




import Vue from "vue"
import { setRefererPath } from "~/uiLogics/webStorage/profileCreationReferer"
import PLoginPage from "~/components/custom/login/PLoginPage.vue"

export default Vue.extend({
  components: {
    PLoginPage,
  },
  beforeRouteEnter(_, from, next) {
    // 前のページのパスが有効なものであれば、登録後の戻り先のパスとして保持
    if (
      process.client &&
      from.matched.length > 0 // 定義しているルーティングに当てはまるものがあるのかを判定
    ) {
      setRefererPath(from.fullPath)
    }
    next()
  },
  layout: "home",
  head(): object {
    return this.$clHelpers.metaTag.generate({
      title: "ログイン",
      currentPath: this.$nuxt.$route.path,
    })
  },
})
