







































































import Vue from "vue"
import { required, email, helpers } from "vuelidate/lib/validators"
import FacebookAuthButton from "~/components/custom/login/FacebookAuthButton.vue"
import GoogleAuthButton from "~/components/custom/login/GoogleAuthButton.vue"
import BoundaryLine from "~/components/guideline/util/BoundaryLine.vue"
import ConversionButton from "~/components/guideline/button/ConversionButton.vue"
import FormInputField from "~/components/guideline/form/field/InputField.vue"
import PasswordInputField from "~/components/guideline/form/field/PasswordField.vue"
import FormWrapper from "~/components/guideline/form/FormWrapper.vue"
import { makeAuthPagePath } from "~/uiLogics/auth/authExecutor"
import { getLastLoginMethod } from "~/uiLogics/webStorage/lastLoginMethod"
import { passwordRegexpattern } from "~/uiLogics/validationPatterns"

const passwordRegex = helpers.regex("passwordRegex", passwordRegexpattern)

export default Vue.extend({
  components: {
    FacebookAuthButton,
    GoogleAuthButton,
    BoundaryLine,
    ConversionButton,
    FormInputField,
    PasswordInputField,
    FormWrapper,
  },
  data() {
    return {
      email: "",
      password: "",
      lastLoginPassword: false,
      lastLoginFacebook: false,
      lastLoginGoogle: false,
      isLoading: false,
      error: "",
    }
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      passwordRegex,
    },
  },
  computed: {
    emailError() {
      if (this.$v.email.$error) {
        if (!this.$v.email.required) {
          return "メールアドレスは必須項目です。"
        }
        if (!this.$v.email.email) {
          return "メールアドレスの形式が不正です。"
        }
      }
      return ""
    },
    passwordError() {
      if (this.$v.password.$error) {
        if (!this.$v.password.required) {
          return "パスワードは必須項目です。"
        }
        if (!this.$v.password.passwordRegex) {
          return "半角の英字、数字、記号を含む8文字以上を設定してください。"
        }
      }
      return ""
    },
    signupUrl() {
      return `${process.env.FRONTEND_WORKER_BASE_URL}/worker/signup/`
    },
  },
  mounted() {
    const lastLoginMethod = getLastLoginMethod() || ""
    this.lastLoginPassword = lastLoginMethod === "password"
    this.lastLoginFacebook = lastLoginMethod === "facebook.com"
    this.lastLoginGoogle = lastLoginMethod === "google.com"
  },
  methods: {
    async emailLogin() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.isLoading = true
      await this.$clAuth
        .loginEmail(this.email, this.password)
        .then(() => {
          const path = makeAuthPagePath({ route: this.$route })
          this.$nuxt.$router.push({ path })
        })
        .catch(() => {
          this.error = "メールアドレスまたはパスワードが正しくありません。"
          this.isLoading = false
        })
    },
    facebookLogin(): void {
      this.$clAuth.loginFacebook()
    },
    googleLogin(): void {
      this.$clAuth.loginGoogle()
    },
  },
})
