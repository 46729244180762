






import Vue from "vue"
import LoginForm from "./LoginForm.vue"
import SimplePage from "~/components/custom/layout/SimplePage.vue"

export default Vue.extend({
  components: {
    LoginForm,
    SimplePage,
  },
})
