










import Vue, { PropType } from "vue"
import SimpleHeader from "~/components/guideline/header/SimpleHeader.vue"
import SimpleFooter from "~/components/guideline/footer/SimpleFooter.vue"

type BgColorType = "gray" | "skyblue"

export default Vue.extend({
  components: {
    SimpleHeader,
    SimpleFooter,
  },
  props: {
    bgColor: {
      type: String as PropType<BgColorType>,
      default: "gray",
    },
  },
  computed: {
    backgroundColor() {
      return this.bgColor === "skyblue"
        ? "g-skyblue-bg-color"
        : "g-gray-bg-color"
    },
  },
})
